import React from 'react'
import {graphql} from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from '../components/AboutUs/CaseStudiesDetailsContent'
import RelatedProducts from '../components/AboutUs/RelatedProducts'
import Seo from "../components/App/Seo"

const CaseDetails = ({data: {markdownRemark: customer}}) => {
  return (
    <Layout>
      <Seo title={`Kundenprojekt: ${customer.frontmatter.customer}`}
           description={customer.excerpt.replace(/\n/g, "").replace(/"/g, "'")}
           image={"/images/og/custproject-logentis-og.png"}
      />
      <Navbar />
      <PageBanner
        pageTitle={`Kundenprojekt Details: ${customer.frontmatter.customer}`}
        homePageText="Home"
        homePageUrl="/"
        makeH1={false}
        activePageText={customer.frontmatter.customer}
        breadcrumbs={[["Home", "/"], ["Übersicht", "/kundenprojekte/"]]}
      />
      <CaseStudiesDetailsContent customer={customer}/>
      <RelatedProducts />
      <Footer />
    </Layout>
  );
}

export default CaseDetails

export const pageQuery = graphql`
  query qandaById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      frontmatter {
        customer
        customer_address
        customer_website
        customer_case_study
        description
        about
        testimonial
        testimonial_source
        testimonial_source_position
        testimonial_icon
        highlights
        product
        customer_logo {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
        featuredimage {
          childImageSharp {
            gatsbyImageData
          }
        }
        textimage {
          childImageSharp {
            gatsbyImageData
          }
        }        
        title
      }
      fields {
        slug
        collection
      }
      html
    }
  }
`
