import React from 'react'
import {Link} from 'gatsby'
import NetversysSignet from "../../assets/images/Netversys_Black_1C_Signet.svg"
import NetstorsysSignet from "../../assets/images/Netstorsys_Black_1C_Signet.svg"

const RelatedProducts = ({isNetversys = true}, isNetstorsys = false) => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>
                        {isNetstorsys && isNetstorsys && "Mehr zum Produkt"}
                    </h2>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={NetversysSignet} alt={"Netversys Signet"} width={"80px"} className={"m-2"} />
                            </div>
                            <h3>
                                <Link to="/versandsoftware/" style={{textDecoration: "none"}}>
                                    NETVERSYS
                                </Link>
                            </h3>
                            <p>
                                Versandsoftware wie sie sein soll. Schnell, komfortabel und mit allen Carriern die Sie benötigen.
                                Starten Sie mit NETVERSYS durch und seien Sie der Konkurrenz vorraus.
                            </p>

                            <Link to="/versandsoftware/" className="read-more-btn" style={{textDecoration: "none"}}>
                                Mehr erfahren <i className="flaticon-right"/>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={NetstorsysSignet} alt={"Netversys Signet"} width={"80px"} className={"m-2"} />
                            </div>
                            <h3>
                                <Link to="/lagerverwaltung/" style={{textDecoration: "none"}}>
                                    NETSTORSYS
                                </Link>
                            </h3>
                            <p>
                                Die Software für Lagerlogistik im Zeitalter der Cloud. Erleben Sie modernes Warehouse
                                Management für den Bruchteil der Kosten anderer Systeme.
                            </p>

                            <Link to="/lagerverwaltung/" className="read-more-btn" style={{textDecoration: "none"}}>
                                Mehr erfahren <i className="flaticon-right"/>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default RelatedProducts
