import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseStudiesSidebar = ({ customer }) => {
  return (
    <div className="case-studies-sidebar-sticky">
      <div className="case-studies-details-info">
        <ul>
          <li>
            <GatsbyImage image={getImage(customer.frontmatter.customer_logo)} alt={customer.frontmatter.customer} />
          </li>
          <li>
            <div className="icon" style={{top: "0"}}>
              <i className="bx bx-map"/>
            </div>
            <span>Standort:</span>
            {customer.frontmatter.customer_address}
          </li>
          <li>
            <div className="icon" style={{top: "0"}}>
              <i className="bx bx-purchase-tag"/>
            </div>
            <span>LOGENTIS Produkt:</span>
            {customer.frontmatter.product.map((prod) => (
              <div>{prod}</div>
            ))}

          </li>
          <li>
            <div className="icon" style={{top: "0"}}>
              <i className="bx bx-globe"/>
            </div>
            <span>Web:</span>
            <a href={customer.frontmatter.customer_website} target="_blank"
               rel="noreferrer">{customer.frontmatter.customer_website}</a>
          </li>
          {customer.frontmatter.customer_case_study &&
            <li>
              <div className="icon" style={{top: "0"}}>
                <i className="bx bx-file"/>
              </div>
              <span>Case-Study</span>
              <a href={customer.frontmatter.customer_case_study} target="_blank"
                 rel="noreferrer">Herunterladen</a>
            </li>
          }
        </ul>
      </div>
    </div>
  )
}

export default CaseStudiesSidebar
