import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CaseStudiesSidebar from "./CaseStudiesSidebar"
import Content from "../../components/App/Content"
import SingleTestimonialItem from "../Common/SingleTestimonialItem"

const CaseStudiesDetailsContent = ({ customer }) => {
  return (
    <section className="case-studies-details-area pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="case-studies-details-image">
              <GatsbyImage alt={customer.frontmatter.title}
                           image={getImage(customer.frontmatter.featuredimage)}/>
            </div>
            <div className="case-studies-details-desc">
              <span className="sub-title">{customer.frontmatter.product.join(" | ")}</span>
              <h1 className={"h3 mt-4"}>{customer.frontmatter.title}</h1>
              <p>{customer.frontmatter.description}</p>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="image">
                    <GatsbyImage alt={customer.frontmatter.title}
                                 image={getImage(customer.frontmatter.textimage)} className={"m-5"}/>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="content">
                    <h3>Projekthighlights:</h3>
                    <ul>
                      {customer.frontmatter.highlights.map((highlight) => (
                        <li key={highlight}>{highlight}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <Content htmlContent={customer.html} />

              {customer.frontmatter.testimonial && <SingleTestimonialItem testimonial={
                {
                  text: customer.frontmatter.testimonial,
                  source: customer.frontmatter.testimonial_source,
                  position: customer.frontmatter.testimonial_source_position,
                  icon: customer.frontmatter.testimonial_icon
                }} />
              }
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <CaseStudiesSidebar customer={customer} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudiesDetailsContent
