import React from "react"
import man1 from "../../assets/images/testimonials/man1.png"
import man2 from "../../assets/images/testimonials/man2.png"
import woman1 from "../../assets/images/testimonials/woman1.png"
import woman2 from "../../assets/images/testimonials/woman2.png"

const m = new Map();
m.set("man1", man1);
m.set("man2", man2);
m.set("woman1", woman1);
m.set("woman2", woman2);

const SingleTestimonialItem = ({ testimonial }) => {
  return (
    <div className={"border pt-3 mt-5"} style={{borderColor: "#eee"}}>
      <div className="single-testimonials-item">
        <p className={"text-black-50"}><em>{testimonial.text}</em></p>

        <div className="client-info">
          <div className="d-flex justify-content-center align-items-center">
            <img src={m.get(testimonial.icon)} alt="testimonial" />
            <div className="title">
              <h3>{testimonial.source}</h3>
              <span className={"text-black-50"}>{testimonial.position}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleTestimonialItem
